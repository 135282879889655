<template>
    <div class="d-flex justify-content-center align-items-center vh-100">
        <div class="text-center">
            <button @click="$router.push({ name: 'terrains' })" class="btn btn-primary p-4 fs-3">
                Choisissez le terrain de jeu en cliquant ici
            </button>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>